import { Icon } from "@iconify/react";
import React from "react";
import { apps } from "../../../../apps/apps";
import { Link } from "@mui/material";
import { scrollToTop } from "../../../functions/scrollToTop";
import "./Footer.scss";

const Footer = ({ app }) => {
  const iconStyle = { fontSize: "2em", color: "white", margin: ".2em" };
  const socialIcons = {
    facebook: "mdi:facebook",
    instagram: "mdi:instagram",
    twitter: "mdi:twitter",
    youtube: "mdi:youtube",
    linkedin: "mdi:linkedin",
    github: "mdi:github",
    discord: "ic:baseline-discord",
    pinterest: "mdi:pinterest",
    tiktok: "ic:baseline-tiktok",
  };

  const footerCategoryStyle = {
    flex: 1,
    marginLeft: "1em",
    marginRight: "1em",
    color: "white",
  };

  const CategoryHeader = ({ title }) => {
    return <p style={{ fontWeight: "bold", fontSize: "1.3em" }}>{title}</p>;
  };

  const PageLink = ({ item }) => {
    const { name, path } = item;

    return (
      <div style={{ margin: ".5em 0em" }}>
        <Link
          underline="hover"
          href={path + "/"}
          style={{ color: "white", fontSize: "1.2em" }}
          onClick={() => {
            scrollToTop();
          }}
        >
          {name}
        </Link>
      </div>
    );
  };

  return (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <div
        style={{
          height: "3em",
          background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, ${app?.backgroundColor} 100%)`,
          width: "100%",
        }}
      />
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          backgroundColor: app?.backgroundColor,
        }}
      >

        <div
          style={{
            width: "100%",
            height: "1px",
            backgroundColor: "white",
            opacity: ".6",
            marginLeft: "2em",
            marginRight: "2em",
            marginBottom: "3em",
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "calc(100% - 2em)",
            maxWidth: "70em",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              width: "100%",
            }}
          >
            <div style={{ ...footerCategoryStyle }}>
              <CategoryHeader title={"All Apps"} />
              {apps.map((app, index) => {
                return <PageLink key={index} item={app} />;
              })}
            </div>
            <div style={{ ...footerCategoryStyle }}>
              <CategoryHeader title={"About"} />
              <PageLink
                item={{
                  name: "Privacy Policy",
                  path: `/privacyPolicy${app?.path || "/picabook"}`,
                }}
              />
              <PageLink
                item={{
                  name: "Terms of Service",
                  path: `/termsOfService${app?.path || "/picabook"}`,
                }}
              />
            </div>
            <div style={{ ...footerCategoryStyle }}>
              <CategoryHeader title={"Contact"} />
              <PageLink item={{ name: "App Support", path: "/contact" }} />
              <PageLink
                item={{ name: "Business Inquiries", path: "/businessInquiry" }}
              />
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "2em",
              marginRight: "2em",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "1px",
                backgroundColor: "white",
                opacity: ".6",
                marginTop: "2em",
              }}
            />
            <div
              style={{
                flexWrap: "wrap",
                minWidth: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                margin: 0,
                color: "white",
                columnGap: "1em",
              }}
            >
              <p style={{ fontWeight: "600", fontSize: "2em" }}>MyFortuna</p>
              <p>{`© MyFortuna ${new Date().getFullYear()}`}</p>
              <div
                className={"footerCompanyNameSpacer"}
                style={{ flex: "1 0 0" }}
              />
              <div style={{ display: "flex" }}>
                {Array.isArray(app?.socialLinks) &&
                  app?.socialLinks.length > 0 &&
                  app?.socialLinks.map((socialLink, index) => {
                    return (
                      <a
                        key={index}
                        title={socialLink?.type || "social link"}
                        style={{ ...iconStyle }}
                        href={socialLink?.url}
                      >
                        <Icon
                          icon={socialIcons[socialLink.type] || "tabler:social"}
                        />
                      </a>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div >
    </div>

  );
};

export default Footer;
