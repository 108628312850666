import { cloneDeep } from "lodash";

const subtitles = [
  "Scan vehicles for paint damage.",
  "Pickup and Drop-off Inspections made easy.",
  "AI-powered car damage detection on the go.",
];

const introduction = [
  {
    type: "text/main_title_subtitle",

    verticalAlign: "center",
    horizontalAlign: "center",

    titles: ["CarMark"],
    subtitles,
    textStyle: {
      textAlign: "center",
    },
  },
];

const introduction_showcase = [
  {
    type: "screenshot/showcase_double_pivot",


    screenshots: [
      {
        src: "carmark/assets/screenshots/scan_page.png",
        alt: "Screenshot of a scan page",
      },
      {
        src: "carmark/assets/screenshots/inspection_list.png",
        alt: "Screenshot of past inspections",
      },
    ],
  },
];

const main_feature_descriptions = [
  {
    type: "layout/split_horizontal",
    contentLeft: [
      {
        type: "screenshot/single_enlarged",

        horizontalAlign: "start",

        screenshots: [{
          src: "carmark/assets/screenshots/highlighted_damage.png",
          alt: "Screenshot of highlighted damage on a car",
        }],
      },
    ],
    contentRight: [
      {
        type: "text/title_subtitle",

        horizontalAlign: "start",

        titles: ["Make Damage More Visible"],
        subtitles: [
          "Turn you phone into a powerful tool for spotting paint imperfections.",
        ],
      },
      {
        type: "text/title_subtitle",

        horizontalAlign: "start",

        titles: ["Backup Inspection Data"],
        subtitles: [
          "Uploaded photos are stored in the cloud for easy access.",
        ],
      },
    ],
  },
];

const shared_feature_descriptions = [
  {
    type: "layout/split_horizontal",
    contentRight: [
      {
        type: "screenshot/single_enlarged",

        horizontalAlign: "start",
        screenshotSide: "right",

        screenshots: [{
          src: "carmark/assets/screenshots/sharing_feature.png",
          alt: "Screenshot of highlighted damage on a car",
        }],
      },
    ],
    contentLeft: [
      {
        type: "text/title_subtitle",

        textStyle: {
          textAlign: "end",
        },

        titles: ["Annotate Findings"],
        subtitles: [
          "Drawing and text tools available when sharing inspection data.",
        ],
      },
      {
        type: "text/title_subtitle",

        textStyle: {
          textAlign: "end",
        },

        titles: ["Share Edited Photos"],
        subtitles: [
          "Scanned, annotated and summarized phots can be sent through any messaging app or email.",
        ],
      },
    ],
  },
]


const mobile_main_feature_descriptions = cloneDeep(main_feature_descriptions);
mobile_main_feature_descriptions[0].contentLeft[0].containerStyle = {
  justifyContent: "end",
};

const mobile_shared_feature_descriptions = cloneDeep(shared_feature_descriptions);
mobile_shared_feature_descriptions[0].contentRight[0].containerStyle = {
  justifyContent: "start",
};

export const carmark_config = {
  name: "CarMark",
  subtitles,
  backgroundColor: "#1dd3b0",

  //Vanta
  highlightColor: "#00FFCC",
  midtoneColor: "#FF82E0",
  lowlightColor: "#36827F",
  baseColor: "#1DD3B0",

  path: "/carmark",

  title: "CarMark: AI Visual Inspections for Vehicles",
  description:
    "CarMark is designed as a tool to make vehicle inspections easier, faster and more accurate. It detects scratches, dents, chips and other common damages highlighting them so that they are easier to see.",
  keywords:
    "AI Vehicle Inspections, Automated Car Damage Detection, Organize Pickup and Drop-off Inspections",

  //googlePlayLink: "https://play.google.com/store/apps/details?id=app.myfortuna.car_mark",
  //appStoreLink: "https://apps.apple.com/us/app/picabook-picture-book-creator/id1643338958",

  sections: [
    {
      type: "layout/split_horizontal",
      leftFlex: 4,
      rightFlex: 6,
      contentLeft: [...introduction],
      contentRight: [...introduction_showcase],
    },
    ...main_feature_descriptions,
    ...shared_feature_descriptions,
  ],
  mobileSections: [
    ...introduction,
    ...introduction_showcase,
    ...mobile_main_feature_descriptions,
    ...mobile_shared_feature_descriptions,
  ],
};
