import { cloneDeep } from "lodash";

const subtitles = [
  "Everyday Q&A for the curious.",
  "See what other people think.",
  "Decentralized knowledge at your fingertips.",
]

const introduction = [
  {
    type: "text/main_title_subtitle",

    verticalAlign: "center",
    horizontalAlign: "center",

    titles: ["Answer Me"],
    subtitles,
    textStyle: {
      textAlign: "center",
    },
  },
];

const introduction_showcase = [
  {
    type: "screenshot/showcase_double_pivot",

    screenshots: [
      {
        src: "answer_me/assets/screenshots/ask_question_screenshot.png",
        alt: "Screenshot of asking a new question",
      },
      {
        src: "answer_me/assets/screenshots/get_answers_screenshot.png",
        alt: "Screenshot of getting answers to the asked question",
      },
    ],
  },
];

const main_feature_descriptions = [
  {
    type: "layout/split_horizontal",
    contentLeft: [
      {
        type: "screenshot/single_enlarged",

        horizontalAlign: "start",

        screenshots: [
          {
            src: "answer_me/assets/screenshots/give_answers_screenshot.png",
            alt: "Screenshot of an answer assignments inside of the app",
          },
        ],
      },
    ],
    contentRight: [
      {
        type: "text/title_subtitle",

        horizontalAlign: "start",

        titles: ["1. Help others"],
        subtitles: [
          "To submit a question, first provide a couple of quick answers",
        ],
      },
      {
        type: "text/title_subtitle",

        horizontalAlign: "start",

        titles: ["2. Get your answer"],
        subtitles: [
          "Other users go through the same process and you get multiple results",
        ],
      },
    ],
  },
];
const mobile_main_feature_descriptions = cloneDeep(main_feature_descriptions);
mobile_main_feature_descriptions[0].contentLeft[0].containerStyle = {
  justifyContent: "end",
};

export const answer_me_config = {
  name: "Answer Me",
  subtitles,
  backgroundColor: "#0070ff",
  path: "/answer_me",

  //Vanta
  highlightColor: "#3DD5FF",
  midtoneColor: "#867DFF",
  lowlightColor: "#0F00FF",
  baseColor: "#0070ff",

  //SEO
  title: "Answer Me: Everyday Q&A",
  description:
    "Get human answers fast on any topic with Answer Me. Ask, and receive up to 3 unique answers to your questions. Connect, learn, and share opinions in a fun way.",
  keywords:
    "Answer Me, Q&A, Everyday Questions, Quick Responses, Best Human Answers, Share Opinions, Connect with Others, Learn Perspectives",

  googlePlayLink:
    "https://play.google.com/store/apps/details?id=com.myfortuna.answer_me",
  //appStoreLink: "https://apps.apple.com/us/app/picabook-picture-book-creator/id1643338958",

  sections: [
    {
      type: "layout/split_horizontal",
      leftFlex: 4,
      rightFlex: 6,
      contentLeft: [...introduction],
      contentRight: [...introduction_showcase],
    },
    ...main_feature_descriptions,
  ],

  mobileSections: [
    ...introduction,
    ...introduction_showcase,
    ...mobile_main_feature_descriptions,
  ],
};
