import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { hydrate, render } from "react-dom";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const firebaseConfig = {
  apiKey: "AIzaSyAxI8X1GyqFyMGneKxVDxrObPQ1vio0ioM",
  authDomain: "my-fortuna-website.firebaseapp.com",
  projectId: "my-fortuna-website",
  storageBucket: "my-fortuna-website.appspot.com",
  messagingSenderId: "296126244526",
  appId: "1:296126244526:web:83d879fed86332b9242f3f",
  measurementId: "G-Z7ZB8G2SSX"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


// const root = ReactDOM.createRoot(document.getElementById('root'));
const root = document.getElementById('root')
if (root.hasChildNodes()) {
  hydrate(<App />, root);
} else {
  render(  <React.StrictMode>
    <App />
  </React.StrictMode>, root);
}

const sendToAnalytics = async ({ id, name, value })  =>  {
  logEvent(analytics, name, {
    event_category: 'Web Vitals',
    event_label: id,
    value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
    non_interaction: true, // avoids affecting bounce rate.
  });
}

reportWebVitals(sendToAnalytics);
