import { answer_me_config } from "./answer_me/answer_me_config";
import { picabook_config } from "./picabook/picabook_config";
import { tegra_config } from "./tegra/tegra_config";
import { carmark_config } from "./carmark/carmark_config";

export const apps = [
  picabook_config,
  carmark_config,
  tegra_config,
  answer_me_config,
];