import React from "react";

const TitleSubtitle = ({
  type,
  titles,
  subtitles,
  textStyle = {},
  titleStyle = {},
  subtitleStyle = {},
}) => {
  return (
    <div style={{
      width: "100%",
    }}>
      <h2
        style={{
          textShadow: "1px 1px 1px #ADBEBA",
          fontFamily: "Roboto",
          fontWeight: 700,
          fontSize: "2em",
          color: "white",
          ...(subtitles && subtitles.length > 0
            ? { marginBottom: "0.5em" }
            : {}),
          ...textStyle,
          ...titleStyle,
        }}
      >
        {titles[0]}
      </h2>
      {Array.isArray(subtitles) && subtitles.length > 0 && (
        <p
          style={{
            fontFamily: "Roboto",
            fontWeight: 400,
            fontSize: "1.5em",
            color: "white",
            ...(subtitles.length > 0 ? { marginTop: "0.5em" } : {}),
            ...textStyle,
            ...subtitleStyle,
          }}
        >
          {subtitles[0]}
        </p>
      )}
    </div>
  );
};

export default TitleSubtitle;
