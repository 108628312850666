import DownloadHeader from "../DownloadHeader";
import { usePageTitle } from "../../../hooks/usePageTitle";
import { Template } from "../../template/Template";
import Footer from "../Footer/Footer";
import MetaBlocks from "../MetaBlocks";
import "./LandingPage.scss";
import VantaBackground from "../Wrappers/VantaBackground";

const LandingPage = ({ app }) => {
  usePageTitle({});

  return (
    <VantaBackground
      highlightColor={app?.highlightColor}
      midtoneColor={app?.midtoneColor}
      lowlightColor={app?.lowlightColor}
      baseColor={app?.baseColor}
      containerStyle={{
        overflowY: "hidden",
      }}
    >
      <MetaBlocks app={app} />
      <DownloadHeader app={app} />
      <div className={"landingPageMobileContainer"} style={{ height: "4em" }} />
      <div className={"landingPageDesktopContainer"}>
        {app?.sections.map((section, index) => {
          return (
            <div key={index}>
              <Template type={section?.type} config={section} />
            </div>
          );
        })}
      </div>
      <div className={"landingPageMobileContainer"}>
        {app?.mobileSections.map((section, index) => {
          return (
            <div key={"mobile_" + index}>
              <Template type={section?.type} config={section} />
            </div>
          );
        })}
      </div>
      <Footer app={app} />
    </VantaBackground>
  );
};

export default LandingPage;
