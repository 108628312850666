
const VantaBackground = ({ children,
  highlightColor,
  midtoneColor,
  lowlightColor,
  baseColor,
  containerStyle,
}) => {

  //Animated background from vantaJS Doesn't work with react-snap out the box

  // const covertCSSColorToHex = (cssColor) => {
  //   return parseInt(`0x${cssColor.slice(1).toUpperCase()}`, 16);
  // }

  // const [vantaEffect, setVantaEffect] = useState(null)
  // const vantaRef = useRef(null)
  // useEffect(() => {
  //   if (!vantaEffect) {
  //     setVantaEffect(FOG({
  //       el: vantaRef.current,
  //       mouseControls: false,
  //       touchControls: false,
  //       gyroControls: false,
  //       minHeight: 200.00,
  //       minWidth: 200.00,
  //       highlightColor: highlightColor ? covertCSSColorToHex(highlightColor) : 0xffffff,
  //       midtoneColor: midtoneColor ? covertCSSColorToHex(midtoneColor) : 0x34daff,
  //       lowlightColor: lowlightColor ? covertCSSColorToHex(lowlightColor) : 0x2b00ff,
  //       baseColor: baseColor ? covertCSSColorToHex(baseColor) : 0x51f99c,
  //       blurFactor: 0.90,
  //       speed: 1,
  //       zoom: .5
  //     }))
  //   }
  //   return () => {
  //     if (vantaEffect) vantaEffect.destroy()
  //   }
  // }, [
  //   vantaEffect,
  //   highlightColor,
  //   midtoneColor,
  //   lowlightColor,
  //   baseColor
  // ]);

  return (
    <div
      // ref={vantaRef}
      style={{
        backgroundColor: baseColor,
        ...containerStyle,
      }}
    >
      {children}
    </div>
  );


}

export default VantaBackground;