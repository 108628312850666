import React from "react";
import { useDynamicImage } from "../../../../hooks/useDynamicImage";
import { Link } from "@mui/material";

const AppListEntry = ({ app }) => {

  const appName = app?.path?.substring(1);

  const currentImage = useDynamicImage({ src: `${appName}/assets/images/icon.png` });

  return <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
    <div style={{ width: "4em" }}>
      <img
        style={{ maxWidth: "100%", borderRadius: "0.5em" }}
        src={currentImage}
        alt={`${appName} app icon`}
      />
    </div>
    <div style={{ display: "flex", flexDirection: "column", flex: 1, margin: ".5em", marginBottom: "1em" }}>
      <Link href={app?.path + "/"} style={{ color: "white", fontWeight: "bold", fontSize: "1.5em", margin: 0 }}>{app?.name ?? "App Name"}</Link>
      <p style={{ margin: 0 }}>{app?.subtitles?.[0] ?? "App Description"}</p>
    </div>
  </div>
}

export default AppListEntry;